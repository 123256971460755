import { module } from 'modujs';
import Swiper, { Navigation, Autoplay } from 'swiper';

Swiper.use([Navigation, Autoplay]);

export default class extends module {
    constructor(m) {
        super(m);

        // VARS
        this.swiper = null;
    }

    init() {

        // SWIPER
        if(this.$('item').length > 1) {
            this.swiper = new Swiper(this.$('swiper')[0], {
                loop: true,
                slidesPerView: 1,
                simulateTouch: true,
                autoplay: {
                    delay: 5000,
                },
                navigation: {
                    prevEl: this.$('prev')[0],
                    nextEl: this.$('next')[0],
                }
            });
        } else {
            this.$('arrows')[0].style.display = 'none';
        }
    }

}
