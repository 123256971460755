import { module } from 'modujs';
import axios from 'axios';
import Qs from 'qs';
import gsap from "gsap";

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {
        this.el.style.opacity = 0;

        let ajaxData = {
            action: 'ajax_function_manager',
            fn: 'getBourse',
        };

        axios({
            method:'get',
            url: AJAX_URL + '?' + Qs.stringify( ajaxData ),
            responseType: 'json'
        }).then((response) => {
                this.$('last')[0].innerHTML = response.data.last[0];
                this.$('variation')[0].innerHTML = response.data.variation[0];
                this.$('date')[0].innerHTML = response.data.date;
            })
            .catch((error) => {
                console.log(error);
            })
            .then(() => {
                gsap.to(this.el, {
                    duration: 0.6,
                    alpha: 1,
                    ease: 'power4.out'
                })
            });
    }
}
