import { module } from 'modujs';
import Swiper, { Navigation } from 'swiper';
import {StoreSingleton} from "../../singletons/store";
import {offset} from "../../utils/utils";

Swiper.use([Navigation]);

export default class extends module {
    constructor(m) {
        super(m);

        // VARS
        this.swiper = null;
        this.currentItem = this.$('item')[0];
        this.currentContent = this.$('content')[0];

        // EVENTS
        this.events = {
            click: {
                item: '_showCurrentContent'
            }
        }
    }

    init() {

        // BUTTONS
        if(this.$('item').length <= 5) {
            this.$('prev')[0].style.display = 'none';
            this.$('next')[0].style.display = 'none';
        }

        // SWIPER
        if(!StoreSingleton.isMobile) {
            this.swiper = new Swiper(this.$('swiper')[0], {
                direction: 'vertical',
                loop: false,
                slidesPerView: 5,
                simulateTouch: false,
                navigation: {
                    prevEl: this.$('prev')[0],
                    nextEl: this.$('next')[0],
                }
            });
        }

        // ACCORDION
        if(StoreSingleton.isMobile) {
            this.$('content').forEach(item => {
                let _target = item.querySelector('.title');
                if(!_target) {
                    _target = item.querySelector('.number')
                }
                _target.addEventListener('click', () => {
                    if(!item.classList.contains('active')) {
                        if(this.currentContent)
                            this.currentContent.classList.remove('active');

                        this.currentContent = item;
                        this.currentContent.classList.add('active');
                        window.scrollTo(0, (offset(this.currentContent).top - 79));
                    } else {
                        this.currentContent.classList.remove('active');
                        this.currentContent = null;
                    }
                })
            });
        }
    }

    /**
     * Show current content
     * @private
     */
    _showCurrentContent(event) {
        const _id = event.currentTarget.dataset.contentId;
        this.currentItem.classList.remove('active');
        this.currentContent.classList.remove('active');

        this.currentItem = event.currentTarget;
        this.currentContent = this.el.querySelector('.swiper-item.s-' + _id);

        this.currentItem.classList.add('active');
        this.currentContent.classList.add('active');
    }
}
