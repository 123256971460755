import { module } from 'modujs';
import gsap from 'gsap';

export default class extends module {
    constructor(m) {
        super(m);

        // VAR
        this.link = '';

        // EVENTS
        this.events = {
            click: {
                valid: '_valid',
                refuse: '_refuse'
            }
        }
    }

    init() {}

    show(i__link) {
        this.link = i__link.link;
        gsap.to(this.el, {
            duration: 0.6,
            autoAlpha: 1,
            ease: 'power4.out'
        });
    }

    _valid(event) {
        event.preventDefault();
        gsap.to(this.el, {
            duration: 0.6,
            autoAlpha: 0,
            ease: 'power4.out'
        });
        window.open(this.link, '_blank');
    }

    _refuse(event) {
        event.preventDefault();
        gsap.to(this.el, {
            duration: 0.6,
            autoAlpha: 0,
            ease: 'power4.out'
        });
    }
}
