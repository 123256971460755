import { module } from 'modujs';
import {StoreSingleton} from "../../singletons/store";
import SlimSelect from 'slim-select'

export default class extends module {
    constructor(m) {
        super(m);

        // VAR
        this.currentSubmenu = null;
        this.offset = StoreSingleton.isMobile ? 50 : 50;

        // EVENTS
        this.events = {
            mouseenter: {
                main: '_openSubmenu',
                submenu: '_keepSubmenuOpen',
                logo: '_hideSubmenu'
            },
            mouseleave: {
                bottombar: '_hideSubmenu',
                submenu: '_hideSubmenu'
            },
            click: {
                searchbutton: '_openSearchForm',
                closesearch: '_hideSearchForm',
                openmobile: '_openMobileMenu'
            }
        }
    }

    /**
     * INIT
     */
    init() {

        this.langSelect = new SlimSelect({
            select: '#lang',
            showSearch: false,
        });

        // EVENTS
        this.$('input')[0].addEventListener('keyup', (e) => {
            if (e.key === 'Enter' || e.keyCode === 13) {
                this.$('submit')[0].click();
            }
        })

        this.$('inputlang')[0].addEventListener('change', (e) => {
           window.location.href = e.currentTarget.value;
        });

        window.addEventListener('scroll', this._scroll.bind(this), {passive: true});
    }

    /**
     * Open submenu
     * @private
     */
    _openSubmenu(i__event) {

        const _class = i__event.currentTarget.dataset.pageid;
        const _sub = this.el.querySelector('.submenu.' + _class);

        this._hideSubmenu();
        this._hideSearchForm();

        if(_sub) {
            this.currentSubmenu = _sub;
            this.currentSubmenu.classList.add('visible');
        }
    }

    /**
     * Hide submenu
     * @private
     */
    _hideSubmenu() {
        if(this.currentSubmenu) {
            this.currentSubmenu.classList.remove('visible');
            this.currentSubmenu = null;
        }
    }

    /**
     * Keep submenu open
     * @param i__event
     * @private
     */
    _keepSubmenuOpen(i__event) {
        this.currentSubmenu = i__event.currentTarget;
        this.currentSubmenu.classList.add('visible');
    }

    /**
     * Open search form
     * @private
     */
    _openSearchForm() {
        this._hideSubmenu();
        this.$('search')[0].classList.add('visible');
    }

    /**
     * Close search form
     * @private
     */
    _hideSearchForm() {
        this.$('search')[0].classList.remove('visible');
    }

    /**
     * Open mobile menu
     * @private
     */
    _openMobileMenu() {
        this.call('open', 'MenuMobile');
    }

    /**
     * SCROLL
     */
    _scroll() {
        const _wTop = window.scrollY;
        if(_wTop >= this.offset && !this.el.classList.contains('out')) {
            this.el.classList.add('out');
        } else if(_wTop < this.offset && this.el.classList.contains('out')) {
            this.el.classList.remove('out');
        }
    }
}
