import { module } from 'modujs';
import gsap from 'gsap';
import {SplitText} from "gsap/SplitText";

gsap.registerPlugin(SplitText);

export default class extends module {
    constructor(m) {
        super(m);

        // VARS
        this.index = -1;
        this.prevIndex = null;
        this.isFirstLoad = true;
    }

    init() {
        // SPLIT
        new SplitText('.slider-item .title', {type:"words", wordsClass: "word word++"});

        // SLIDE
        this._slide();
    }

    _slide() {

        if(this.index != -1)
            this.prevIndex = this.index;

        if(this.index < this.$('slide').length - 1)
            this.index++;
        else
            this.index = 0

        const _tl = gsap.timeline({
            delay: 0.5,
            onComplete: () => {
                setTimeout(() => {
                    this._slide();
                }, 3000);
            }
        });

        if(this.prevIndex != null) {
            _tl.to(this.$('slide')[this.prevIndex].querySelectorAll('.word'), {
                duration: 1,
                y: -20,
                alpha: 0,
                rotation: 0.1,
                stagger: 0.05,
                ease: 'power4.out'
            }, 0);
            _tl.set(this.$('b-right')[this.prevIndex], {
                zIndex: 1,
            }, 0);
            _tl.addLabel('prev');
        } else {
            _tl.addLabel('prev');
        }

        _tl.set(this.$('slide')[this.index].querySelectorAll('.word'), {
            y: 20
        });
        _tl.set(this.$('b-right')[this.index], {
            zIndex: 10,
        });

        if(this.isFirstLoad) {
            _tl.to(this.el.querySelector('.title-left'), {
                duration: 1,
                y: 0,
                alpha: 1,
                rotation: 0.1,
                ease: 'power4.out',
            }, 'prev');
            _tl.to(this.el.querySelector('.button'), {
                duration: 1,
                y: 0,
                alpha: 1,
                rotation: 0.1,
                ease: 'power4.out'
            }, 'prev+=0.1');
        }
        _tl.to(this.$('slide')[this.index].querySelectorAll('.word'), {
            duration: 1,
            y: 0,
            alpha: 1,
            rotation: 0.1,
            stagger: 0.05,
            onStart: () => {
                this.$('slide')[this.index].style.display = "block";
            },
            ease: 'power4.out'
        }, 'prev');
        _tl.to(this.$('b-right')[this.index], {
            duration: 0.8,
            alpha: 1,
            ease: 'power4.out',
            onComplete: () => {
                if(this.prevIndex != null) {
                    gsap.set(this.$('b-right')[this.prevIndex], {
                        zIndex: 1,
                        alpha: 0
                    });
                }
            }
        }, 'prev');
    }
}
