/**
 * Find value by prefiw
 * @param object
 * @param prefix
 * @returns {*}
 */
export function findValueByPrefix(i__object, i__prefix) {
    for (let property in i__object) {
        if (i__object.hasOwnProperty(property) &&
            property.toString().startsWith(i__prefix)) {
            return i__object[property];
        }
    }
}

/**
 * Offet
 * @param el
 * @returns {{top: *, left: *}}
 */
export function offset(el) {
    const rect = el.getBoundingClientRect(),
        scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
        scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
}
