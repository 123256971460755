import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);

        // VAR
        this.currentButton = this.el.querySelector('.search-tabs .column-10 .tab.current');
        this.currentTab = this.el.querySelector('.search-content .column-10.current');

        // EVENTS
        this.events = {
            click: {
                button: '_showCurrentTab'
            }
        }
    }

    init() {}

    _showCurrentTab(event) {
        const _id = event.currentTarget.dataset.type;

        this.currentButton.classList.remove('current');
        this.currentTab.classList.remove('current');

        this.currentButton = event.currentTarget;
        this.currentTab = this.el.querySelector('.search-content .column-10.tab-' + _id);

        this.currentButton.classList.add('current');
        this.currentTab.classList.add('current');
    }
}
