import { module } from 'modujs';
import gsap from 'gsap';

export default class extends module {
    constructor(m) {
        super(m);

        // EVENTS
        this.events = {
            click: {
                item: '_openCard',
                close: '_closeCard'
            }
        }
    }

    init() {}

    /**
     * Open card
     * @private
     */
    _openCard(event) {
        const _id = event.currentTarget.dataset.cardid;
        const _card = this.el.querySelector('.card-' + _id);

        const _tl = gsap.timeline();
        _tl.to(_card, {
            duration: 1,
            autoAlpha: 1,
            ease: 'power4.out'
        });
        _tl.to(_card.querySelector('.card__inner'), {
            duration: 1.2,
            x: 0,
            ease: 'power4.inOut'
        }, 0);
    }

    /**
     * Open card
     * @private
     */
    _closeCard(event) {
        const _id = event.currentTarget.dataset.cardid;
        const _card = this.el.querySelector('.card-' + _id);

        const _tl = gsap.timeline();
        _tl.to(_card.querySelector('.card__inner'), {
            duration: 1,
            x: '100%',
            ease: 'power4.inOut'
        }, 0);
        _tl.to(_card, {
            duration: 1,
            autoAlpha: 0,
            ease: 'power4.out'
        }, 0.6);
    }
}
