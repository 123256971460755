import { module } from 'modujs';
import Swiper, {Autoplay, Navigation} from 'swiper';

Swiper.use([Navigation]);

export default class extends module {
    constructor(m) {
        super(m);

        // DOM
        this.dom = {};

        //VAR
        this.glide = null;
        this.margin = window.isMobile ? 0.05 : 0.022831050228;

        // EVENTS
        this._resize = this._resize.bind(this);

    }

    /**
     * Init
     */
    init() {

        // NAV
        if(this.$('item').length < 3) {
            this.$('arrows')[0].style.display = 'none';
        }

        // SWIPER
        const _gap = window.innerWidth * this.margin;
        this.swiper = new Swiper(this.$('swiper')[0], {
            autoplay: {
                delay: 3000,
            },
            loop: false,
            slidesPerView: 1,
            simulateTouch: true,
            spaceBetween: _gap,
            breakpoints: {
                1023: {
                    slidesPerView: 2
                }
            },
            navigation: {
                prevEl: this.$('prev')[0],
                nextEl: this.$('next')[0]
            }
        });

        window.addEventListener('resize', this._resize);
        this._resize();
    }

    /**
     * Resize
     * @private
     */
    _resize() {
        const _gap = window.innerWidth * this.margin;
        this.swiper.params.spaceBetween = _gap;
    }

}
