import { module } from 'modujs';
import gsap from 'gsap';

export default class extends module {
    constructor(m) {
        super(m);

        // EVENTs
        this.events = {
            click: '_open'
        }
    }

    init() {}

    /**
     * OPEN
     * @private
     */
    _open() {
        if(!this.el.classList.contains('open')) {
            this.el.classList.add('open')
            gsap.to(this.$('list')[0], {
                duration: 0.6,
                y: 0,
                autoAlpha: 1,
                ease: 'power4.inOut'
            });
        } else {
            this.el.classList.remove('open')
            gsap.to(this.$('list')[0], {
                duration: 0.6,
                y: 10,
                autoAlpha: 0,
                ease: 'power4.inOut'
            });
        }
    }
}
