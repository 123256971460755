import { module } from 'modujs';
import {offset} from "../../utils/utils";
import {StoreSingleton} from "../../singletons/store";
import SlimSelect from 'slim-select'

export default class extends module {
    constructor(m) {
        super(m);

        // VAR
        this.current = this.el.querySelector('.active');
        this.offset = StoreSingleton.isMobile ? 79 : 160;
        this.select = null;

        // FUNC
        this._resize = this._resize.bind(this);

        // EVENT
        this.events = {
            click: {
                more: '_open',
                overlay: '_openOverlay'
            }
        }
    }

    init() {

        // SELECT
        if(this.$('select')[0]) {
            this.select = new SlimSelect({
                select: this.$('select')[0],
                showSearch: false,
            });
            this.$('form')[0].addEventListener('change', () => {
                if(this.select.selected() != "")
                    window.location.href = window.location.origin + window.location.pathname + '?f=' + this.select.selected();
                else
                    window.location.href = window.location.origin + window.location.pathname;
            });
        }

        // EVENTS
        window.addEventListener('resize', this._resize);

        this.$('item').forEach(item => {
            const _head = item.querySelector('.accordion-item__head');
            const _content = item.querySelector('.accordion-item__content');

            _head.addEventListener('click', () => {
                if(!item.classList.contains('active')) {
                    if(this.current)
                        this.current.classList.remove('active');

                    this.current = item;
                    this.current.classList.add('active');
                    window.scrollTo(0, (offset(this.current).top - this.offset ));
                } else {
                    this.current.classList.remove('active');
                    this.current = null;
                }
            })
        });

        setTimeout(() => {
            this._resize();
        }, 100);
    }

    /**
     * OPEN
     * @private
     */
    _open(event) {
        if(!event.currentTarget.previousElementSibling.classList.contains('open')) {
            event.currentTarget.classList.add('open');
            event.currentTarget.previousElementSibling.classList.add('open');
            event.currentTarget.previousElementSibling.style.height = 'auto';
            this._resize();
        } else {
            event.currentTarget.classList.remove('open');
            event.currentTarget.previousElementSibling.classList.remove('open');
            this._resize();
        }
    }

    /**
     * Resize
     * @private
     */
    _resize() {
        this.$('inner').forEach(item => {
            const _nb = item.querySelectorAll('li');
            if(_nb.length > 5) {
                if(!item.classList.contains('open')) {
                    let _h = 0;
                    for(let i = 0; i < 5; i++) {
                        _h += item.querySelectorAll('li')[i].clientHeight;
                    }
                    item.style.height = _h + 'px';
                }
            } else {
                item.style.height = 'auto';
            }
        });
    }

    _openOverlay(event) {
        event.preventDefault();
        this.call('show', {link: event.currentTarget.href}, 'Legal');
    }
}
