import { html } from "./utils/environment";
import device from "current-device";
import modular from 'modujs';
import * as modules from './modules';
import {StoreSingleton} from "./singletons/store";

import 'swiper/swiper-bundle.css';

export default class App {
    constructor() {

        // DOM
        this.dom = {};

        // DETECT SAFARI
        if (navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1) {document.querySelector('html').classList.add('is-safari')}

        // CLASS MOBILE
        window.isMobile = /Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1;
        if (window.isMobile) {
            html.classList.add('is-mobile');
        }

        // MOBILE
        if(device.mobile() || window.innerWidth <= 1023)
            StoreSingleton.isMobile = true;

        // FUNC
        this.resize = this.resize.bind(this);

        // INIT
        this.init();
    }

    /**
     * INIT
     */
    init() {

        // Namespace
        window.deadwater = {
            app: null,
            scroll: {
                state: {}
            }
        };

        // MODULES
        window.deadwater.app = new modular({
            modules: modules
        });
        window.deadwater.app.init(window.deadwater.app);

        // EVENTS
        window.addEventListener('resize', this.resize.bind(this), {passive: true});
        this.resize();

        // CLASS
        html.classList.add('is-first-load');
        html.classList.remove('is-loading');

        setTimeout(() => {
            html.classList.add('has-dom-ready');
        }, 100);
    }

    /**
     * RESIZE
     */
    resize() {
        const _wWidth = window.innerWidth;

        if(_wWidth <= 1023 && !StoreSingleton.isMobile) {
            window.location.reload();
        } else if(_wWidth > 1023 && StoreSingleton.isMobile) {
            window.location.reload();
        }

        // VH
/*        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);*/
    }

}

/**
 * ON LOAD
 * @private
 */
const __dcl = () => {
    new App();
};

document.addEventListener("DOMContentLoaded", __dcl);
