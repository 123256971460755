import { module } from 'modujs';
import Scrollbar from 'smooth-scrollbar';

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {
        Scrollbar.init(document.querySelector('.tweets__wrapper'), {
            alwaysShowTracks: true,
            continuousScrolling: false
        });
    }
}
