import { module } from 'modujs';
import * as basicLightbox from 'basiclightbox';
import {StoreSingleton} from "../../singletons/store";

export default class extends module {
    constructor(m) {
        super(m);

        // VAR
        this.width = StoreSingleton.isMobile ? 280 : 860;
        this.height = StoreSingleton.isMobile ? 157 : 483;

        // EVENTS
        this.events = {
            click: {
                item: '_openIframe'
            }
        }
    }

    init() {}

    _openIframe(event) {
        event.preventDefault();
        const _url = event.currentTarget.dataset.url;
        const instance = basicLightbox.create(`<iframe src="${_url}?autoplay=1&rel=0" width="${this.width}" height="${this.height}" frameborder="0" allowfullscreen></iframe>`)
        instance.show()
    }
}
