import { module } from 'modujs';
import gsap from 'gsap';

export default class extends module {
    constructor(m) {
        super(m);

        // DOM
        this.dom = {};
        this.dom.images = this.el.querySelectorAll('.thumb-item');
        this.dom.titles = this.el.querySelectorAll('.new-item');
        this.dom.bullets = this.el.querySelectorAll('.bullet-item');

        // VAR
        this.isProcessing = false;
        this.time = null;
        this.index = 0;
        this.previous = 0;

        // FUNC
        this.nextNew = this.nextNew.bind(this);
    }

    init() {
        if(this.dom.images.length > 1)
            this.time = setTimeout(this.nextNew, 3000);

        this.dom.bullets.forEach(item => {
            item.addEventListener('click', () => {
                this.nextNew(gsap.utils.toArray(this.dom.bullets).indexOf(item));
            });
        });
    }

    /**
     *
     */
    nextNew(i__index) {
        if(!this.isProcessing) {
            if(this.time)
                clearTimeout(this.time);

            this.isProcessing = true;
            this.previous = this.index;

            if(typeof i__index === 'undefined') {
                if(this.index < this.dom.images.length - 1)
                    this.index++
                else
                    this.index = 0;
            } else {
                this.index = i__index;
            }

            this.dom.bullets[this.previous].classList.remove('current');
            this.dom.bullets[this.index].classList.add('current');
            this.dom.images[this.previous].style.zIndex = 1;

            const _tl = gsap.timeline({
                onComplete: () => {
                    this.isProcessing = false;
                    gsap.set(this.dom.images[this.previous], {
                        autoAlpha: 0
                    });
                }
            });
            _tl.set(this.dom.images[this.index], {
                zIndex: 10,
                autoAlpha: 0
            });
            _tl.to(this.dom.images[this.index], {
                duration: 1,
                autoAlpha: 1,
                ease: 'power4.out'
            });
            _tl.to(this.dom.titles[this.previous], {
                duration: 1,
                autoAlpha: 0,
                ease: 'power4.out'
            }, 0);
            _tl.to(this.dom.titles[this.index], {
                duration: 1,
                autoAlpha: 1,
                ease: 'power4.out'
            }, 0.4);
        }

        if(this.dom.images.length > 1)
            this.time = setTimeout(this.nextNew, 5000);
    }
}
