import { module } from 'modujs';
import gsap from 'gsap';

export default class extends module {
    constructor(m) {
        super(m);

        // EVENTS
        this.events = {
            click: {
                close: 'close',
                item: '_openSubmenu'
            }
        }
    }

    init() {
        // EVENTS
        this.$('input')[0].addEventListener('keyup', (e) => {
            if (e.key === 'Enter' || e.keyCode === 13) {
                this.$('submit')[0].click();
            }
        });
    }

    /**
     * OPEN
     */
    open() {
        gsap.to(this.el, {
            duration: 0.6,
            autoAlpha: 1,
            ease: 'power4.out'
        });
    }

    /**
     * CLOSE
     */
    close() {
        gsap.to(this.el, {
            duration: 0.6,
            autoAlpha: 0,
            ease: 'power4.out'
        });
    }

    /**
     * Open submenu
     * @private
     */
    _openSubmenu(event) {
        const _t = event.currentTarget;
        const _c = _t.querySelector('.main-list__slave');
        const _u = _t.querySelector('.main-list__slave ul').clientHeight;

        if(_c) {
            if(!_t.classList.contains('open')) {
                _t.classList.add('open');
                gsap.to(_c, {
                    duration: 0.6,
                    height: _u,
                    ease: 'power4.inOut'
                });
            } else {
                _t.classList.remove('open');
                gsap.to(_c, {
                    duration: 0.6,
                    height: 0,
                    ease: 'power4.inOut'
                });
            }
        }
    }
}
